import { Section } from "components/layout/Section";
import heroIphone from "assets/images/for-you/hero-iphone.png";
import { Link } from "react-router-dom";
import { Button } from "components/atoms/Button";
import Animation from "components/atoms/Animation";
import { fadeIn, rightToLeft } from "utils/Animation.util";

const Section1 = () => {
  return (
    <Animation animationConfig={fadeIn(800)}>
      <Section yPadding="py-0">
        <div className="relative flex overflow-hidden">
          <div className="flex flex-col pt-[240px] mb-14 lg:mb-[13rem] lg:pt-[240px]">
            <Animation animationConfig={rightToLeft(1000)}>
              <h1 className="mb-6 text-[44px] sm:text-[72px] font-semibold font-[Montserrat] text-primaryTextBase whitespace-pre-line leading-[120%]  max-w-[737px] lg:text-[80px]">
                Seu futuro pode{" "}
                <span className="text-primaryIntense">render muito</span>
              </h1>
            </Animation>

            <Animation animationConfig={rightToLeft(1000)}>
              <div className="mb-7 lg:mb-10 md:max-w-[737px]">
                <p className="text-lg text-primaryTextBase">
                  Entenda hoje tudo o que o seu dinheiro pode ser amanhã.
                  Conheça um{" "}
                  <b>
                    Previdência sem letras complicadas. Feita para você. Na
                    palma da sua mão.
                  </b>
                </p>
              </div>
            </Animation>

            <Animation animationConfig={rightToLeft(1000)}>
              <div>
                <Link to={"/invista"}>
                  <Button xl>Comece a investir</Button>
                </Link>
              </div>
            </Animation>
          </div>

          <img
            src={heroIphone}
            alt="Hero iPhone"
            className="absolute hidden right-28 top-28 lg:block"
          />
        </div>
      </Section>
    </Animation>
  );
};

export default Section1;
