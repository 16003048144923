import { Section } from "components/layout/Section";
import { Link, useLocation } from "react-router-dom";
import logo from "assets/svg/logo.svg";
import logoDark from "assets/svg/logo-dark.svg";
import logoWhite from "assets/svg/logo-white.svg";
import logoSimple from "assets/svg/logo-simple.svg";
import { Fragment, useEffect, useState } from "react";
import { Button } from "components/atoms/Button";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { fadeIn } from "utils/Animation.util";
import Animation from "components/atoms/Animation";

type INavbarProps = {
  isDark?: boolean;
};

const options = [
  //{ label: "O Mio", route: "/sobre" },
  { label: "Para você", route: "/para-voce" },
  { label: "Para sua empresa", route: "/para-sua-empresa" },
  { label: "Portabilidade", route: "/portabilidade" },
  { label: "Seja um parceiro", route: "/parceiros" },
];

const Navbar = ({ isDark }: INavbarProps) => {
  const location = useLocation();
  const [offset, setOffset] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const activeBar = offset > 0 || isDark;

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <header
        className={`py-4 fixed top-0 z-50 w-full transition-all duration-200 ${
          activeBar
            ? "bg-[#052329] shadow-[0px_2px_4px_0px_rgba(5,35,41,0.08)] lg:bg-white "
            : "bg-transparent"
        }`}
      >
        <Section yPadding="py-0">
          <div className="flex flex-wrap items-center">
            <Link to={"/"} className="flex-1">
              <Animation animationConfig={fadeIn(600)}>
                <img
                  className="block lg:hidden"
                  src={activeBar ? logo : logoWhite}
                  alt=""
                  width={168}
                />

                <img
                  className="hidden lg:block"
                  src={activeBar ? logoDark : logo}
                  alt=""
                  width={168}
                />
              </Animation>
            </Link>

            <nav className="h-full">
              <Animation animationConfig={fadeIn(600)}>
                <ul
                  className={`items-center hidden space-x-8 font-medium text-base navbar lg:flex ${
                    activeBar || isDark ? "text-primaryTextBase" : "text-white"
                  }`}
                >
                  {options.map((option, index) => (
                    <li key={index}>
                      <Link to={option.route}>
                        <p
                          className={`${
                            location.pathname === option.route
                              ? "text-primaryIntense"
                              : activeBar || isDark
                              ? "text-primaryTextBase"
                              : "text-white"
                          } font-medium`}
                        >
                          {option.label}{" "}
                        </p>
                      </Link>
                    </li>
                  ))}

                  <li>
                    <Link to={"/invista"}>
                      <Button xl>Invista agora</Button>
                    </Link>
                  </li>
                </ul>
              </Animation>

              <div className="block lg:hidden">
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => setShowMenu(true)}
                >
                  <Bars3Icon
                    width={32}
                    color={activeBar ? "#3FC5E0" : "white"}
                  />
                </div>
              </div>
            </nav>
          </div>
        </Section>
      </header>

      {showMenu && (
        <div className="fixed inset-0 z-50 overflow-y-scroll bg-white">
          <div className="flex justify-between px-6 pt-12 pb-16">
            <img src={logoSimple} alt="" />
            <XMarkIcon width={24} onClick={() => setShowMenu(false)} />
          </div>

          <ul className={`flex flex-col`}>
            {options.map((option, index) => (
              <Fragment key={index}>
                <Link to={option.route}>
                  <li className="flex items-center justify-between px-6 py-8 ">
                    <p className="font-medium text-neutralTextLight">
                      {option.label}
                    </p>
                    <ChevronRightIcon width={20} color="#005D70" />
                  </li>
                </Link>

                {index < options.length - 1 && (
                  <div className="border-b border-[#DAE3E5] mx-6" />
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export { Navbar };
